import React from 'react';
import { FaAndroid, FaApple } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => {
  const { t } = useTranslation();

  return (
    <div className="iot_tracking__possibility section__padding" id="features">
      <div className="iot_tracking__possibility-image">
        <img src={possibilityImage} alt="features" />
      </div>
      <div className="iot_tracking__possibility-content">
        <h4>{t('possibility.requestAccess')}</h4>
        <h1 className="gradient__text">{t('possibility.title')} <br />{t('possibility.subtitle')} </h1>
        <h4>{t('possibility.appFeaturesTitle')}</h4>
        <p>{t('possibility.appDescription')}</p>
        <ul>
          <li>{t('possibility.unlimitedUsers')}</li>
          <li>{t('possibility.realTimeLocation')}</li>
          <li>{t('possibility.blocking')}</li>
          <li>{t('possibility.dynamicInterface')}</li>
          <li>{t('possibility.report')}</li>
          <li>{t('possibility.mapSelector')}</li>
          <li>{t('possibility.viewStreetButton')}</li>
          <li>{t('possibility.popup')}</li>
          <li>{t('possibility.userSearchSystem')}</li>
          <li>{t('possibility.vehicleSearchSystem')}</li>
          <li>{t('possibility.whatsappSupport')}</li>
          <li>{t('possibility.nationalDateFormat')}</li>
          <li>{t('possibility.webEmailTelegramNotifications')}</li>
          <li>{t('possibility.customSearchField')}</li>
          <li>{t('possibility.ignitionAlerts')}</li>
          <li>{t('possibility.speedAlerts')}</li>
          <li>{t('possibility.motionAlerts')}</li>
          <li>{t('possibility.connectionAlerts')}</li>
          <li>{t('possibility.geofenceAlerts')}</li>
          <li>{t('possibility.alarmAlert')}</li>
          <li>{t('possibility.driverControl')}</li>
          <li>{t('possibility.multiMapConfig')}</li>
          <li>{t('possibility.virtualFence')}</li>
          <li>{t('possibility.lockUnlockCommands')}</li>
          <li>{t('possibility.systemLogo')}</li>
          <li>{t('possibility.onlineVehicleCounter')}</li>
          <li>{t('possibility.offlineVehicleCounter')}</li>
          <li>{t('possibility.movingVehicleCounter')}</li>
          <li>{t('possibility.numberOfVehiclesCounter')}</li>
        </ul>
        <div>
          <h4>{t('possibility.requestAccess')}</h4>
          <p>{t('possibility.downloadText')}</p>
          <p>{t('possibility.downloadLinkText')}</p>
        </div>
        <div className="download-buttons-container">
          <a href="https://www.dropbox.com/scl/fi/xi9vxnmnxfzd3twa4l739/iottrackingV3.2.apk?rlkey=uurnu91z8vfuyp87sqp1y2vnr&dl=0" download>
            <button type="button" className="android-button">
              <FaAndroid className="android-icon" />
              {t('possibility.downloadLinkButtonAndroid')}
            </button>
          </a>
          <a href="https://www.apple.com/fr/app-store/" download>
            <button type="button" className="ios-button">
              <FaApple className="ios-icon" />
              {t('possibility.downloadLinkButtonIOS')}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Possibility;
