import React from "react";
import { useTranslation } from "react-i18next";
import { telegram01, telegram02, telegram03, telegram04 } from "./import";
import "./telegram.css";

function Telegram() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="telegram">
      <h1 className="quick-start-title">{t("telegram.title")}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t("telegram.userGuideTitle")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t("telegram.userGuideParagraph1")}</p>
                <p>{t("telegram.userGuideParagraph2")}</p>
                <p>{t("telegram.userGuideParagraph3")}</p>
                <br />
                <div className="quick-start-image-container">
                  <h3 className="img-title">{t("telegram.startTitle")}</h3>
                  <br />
                  <img
                    src={telegram01}
                    alt="Telegram01"
                    className="img-title"
                    title="Telegram Bot"
                  />
                  <h3 className="img-title">{t("telegram.getAppTokenTitle")}</h3>
                  <br />
                  <img
                    src={telegram03}
                    alt="Telegram02"
                    className="img-title"
                    title="Telgram Bot"
                  />
                  <h3 className="img-title">{t("telegram.addBotAppKeyTitle")}</h3>
                  <br />
                  <img
                    src={telegram02}
                    alt="Telagram03"
                    className="img-title"
                    title="Telegram Bot"
                  />
                </div>
                <br />
                <h3 className="paragraph-title">{t("telegram.howToStartTitle")}</h3>
                <p>{t("telegram.howToStartParagraph1")}</p>
                <p>{t("telegram.howToStartParagraph2")}</p>
                <p>{t("telegram.howToStartParagraph3")}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t("telegram.getControlTitle")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t("telegram.predefinedCommandsParagraph")}</p>
                <br />
                <p>{t("telegram.predefinedCommandsList")}</p>
                <li>{t("telegram.predefinedStartCommand")}</li>
                <li>{t("telegram.predefinedRegisterCommand")}</li>
                <li>{t("telegram.predefinedLocationCommand")}</li>
                <li>{t("telegram.predefinedCommandsCommand")}</li>
                <li>{t("telegram.predefinedGeofencesCommand")}</li>
                <li>{t("telegram.predefinedStatusCommand")}</li>
                <br />
                <div className="quick-start-image-container">
                  <h3 className="img-title">{t("telegram.botTitle")}</h3>
                  <img
                    src={telegram04}
                    alt="Telegram05"
                    className="img-title"
                    title="Telegram Bot"
                  />
                </div>
                <p>{t("telegram.botCommands-Description")}</p>
                <p>{t("telegram.startCommand")}</p>
                <li>{t("telegram.startCommand-Description")}</li>
                <p>{t("telegram.registerCommand")}</p>
                <li>{t("telegram.registerCommand-Description")}</li>
                <p>{t("telegram.locationCommand")}</p>
                <li>{t("telegram.locationCommand-Description")}</li>
                <p>{t("telegram.commandsCommand")}</p>
                <li>{t("telegram.commandsCommand-Description1")}</li>
                <li>{t("telegram.commandsCommand-Description2")}</li>
                <p>{t("telegram.geofenceCommand")}</p>
                <li>{t("telegram.geofenceCommand-Description1")}</li>
                <li>{t("telegram.geofenceCommand-Description2")}</li>
                <li>{t("telegram.geofenceCommand-Description3")}</li>
                <li>{t("telegram.geofenceCommand-Description4")}</li>
                <p>{t("telegram.geofenceCommand-Description5")}</p>
                <p>{t("telegram.statusCommand")}</p>
                <li>{t("telegram.statusCommand-Description")}</li>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default Telegram;
