import React from 'react';
import { useTranslation } from 'react-i18next';
import { addDevice01, addDevice02 } from './import';
import './userManagement.css';

function QuickStart() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="quickStart">
      <h1 className="quick-start-title">{t('quickStart.title')}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t('quickStart.step1.title')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>
                  {t('quickStart.step1.description1')}
                  <a style={{ color: 'purple', fontFamily: 'Arial, sans-serif', fontWeight: '900', fontSize: '20px', lineHeight: 1.5, marginBottom: '1.2em', maxWidth: '100%', wordWrap: 'break-word' }} href="https://iottrack.online"> {t('quickStart.step1.demoLink')}</a>.
                </p>
                <p>
                  {t('quickStart.step1.description2')}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t('quickStart.step2.title')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>
                  {t('quickStart.step2.description1')}
                </p>
                <div className="quick-start-image-container">
                  <img src={addDevice01} />
                  <img src={addDevice02} />
                </div>
                <p>
                  {t('quickStart.step2.description2')}
                </p>
                <p>
                  {t('quickStart.step2.description3')}
                </p>

              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t('quickStart.step3.title')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>
                  {t('quickStart.step3.description1')}
                </p>

                <p>
                  {t('quickStart.step3.description2')}
                  <a href="https://iottrack.online">{t('quickStart.step3.subscriptionLink')}</a>
                </p>
                <p>
                  {t('quickStart.step3.description3')}
                  <a style={{ color: 'purple', fontFamily: 'Arial, sans-serif', fontWeight: '900', fontSize: '20px', lineHeight: 1.5, marginBottom: '1.2em', maxWidth: '100%', wordWrap: 'break-word' }} href="https://shop.iotvts.com/device/supportedDevices"> {t('quickStart.step3.supportedDevicesLink')}</a>.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default QuickStart;
