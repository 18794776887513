import React from 'react';
import { useTranslation } from 'react-i18next';
import Article from '../../components/article/Article';
import { pricing01, pricing02, pricing03, pricing04 } from './imports';
import './pricing.css';

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <div className="iot_tracking__pricing section__padding" id="pricing">
      <div className="iot_tracking__pricing-heading">
        <h1 className="gradient__text">{t('pricing.title')}<br />{t('pricing.subtitle')}</h1>
      </div>
      <div className="iot_tracking__pricing-container">
        <div className="iot_tracking__pricing-container_groupA">
          <Article
            imgUrl={pricing01}
            title={t('pricing.article1.title')}
            text={t('pricing.article1.text')}
          />
        </div>
        <div className="iot_tracking__pricing-container_groupB">
          <Article
            imgUrl={pricing02}
            title={t('pricing.article2.title')}
            text={t('pricing.article2.text')}
          />
          <Article
            imgUrl={pricing03}
            title={t('pricing.article3.title')}
            text={t('pricing.article3.text')}
          />
          <Article
            imgUrl={pricing04}
            title={t('pricing.article4.title')}
            text={t('pricing.article4.text')}
          />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
