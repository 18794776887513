import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../logo.svg';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="iot_tracking__navbar">
      <div className="iot_tracking__navbar-links">
        <div className="iot_tracking__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="iot_tracking__navbar-links_container">
          <p><a href="#home">{t('navbar.home')}</a></p>
          <p><a href="#iotTracking">{t('navbar.iotTracking')}</a></p>
          <p><a href="#features">{t('navbar.features')}</a></p>
          <p><a href="#pricing">{t('navbar.pricing')}</a></p>
          <p><a href="#manual">{t('navbar.documentation')}</a></p>
          <p><a href="https://shop.iotvts.com/device/supportedDevices">{t('navbar.supportedDevices')}</a></p>
        </div>
      </div>
      <div className="iot_tracking__navbar-demobtn">
        <p><a href="https://iottrack.online/">Demo</a></p>
      </div>
      <div className="iot_tracking__navbar-sign">
        <button type="button"><a href="https://shop.iotvts.com">{t('navbar.orderNow')}</a></button>
      </div>
      <div className="iot_tracking__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#0553ac" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#0553ac" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="iot_tracking__navbar-menu_container scale-up-center">
          <div className="iot_tracking__navbar-menu_container-links">
            <p><a href="#home">{t('navbar.home')}</a></p>
            <p><a href="#iotTracking">{t('navbar.iotTracking')}</a></p>
            <p><a href="#features">{t('navbar.features')}</a></p>
            <p><a href="#pricing">{t('navbar.pricing')}</a></p>
            <p><a href="#manual">{t('navbar.documentation')}</a></p>
            <p><a href="https://shop.iotvts.com/device/supportedDevices">{t('navbar.supportedDevices')}</a></p>
          </div>
          <div className="iot_tracking__navbar-menu_container-links-demobtn">
            <p><a href="https://iottrack.online/">Demo</a></p>
          </div>
          <div className="iot_tracking__navbar-menu_container-links-sign">
            <button type="button"><a href="https://shop.iotvts.com">{t('navbar.orderNow')}</a></button>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
