import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import logo from '../../logo.svg';
import './footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="iot_tracking__footer section__padding">
      <div className="iot_tracking__footer-heading">
        <h1 className="gradient__text">{t('footer.heading')}</h1>
      </div>
      <div className="iot_tracking__footer-btn">
        <p><a href="mailto:info@iotvts.com?subject=IoT%20Tracking%20Inquiry&body=Hello,%20I%20am%20interested%20in%20using%20IoT%20Tracking.%20Please%20send%20me%20more%20information.">{t('footer.btn.requestQuote')}</a></p>
      </div>
      <div className="iot_tracking__footer-links">
        <div className="iot_tracking__footer-links_logo">
          <img src={logo} alt="iot_tracking_logo" />
          <p>UCS, Drammen, Norway <br /> All Rights Reserved</p>
        </div>
        <div className="iot_tracking__footer-links_div">
          <h4>{t('footer.links')}</h4>
          <p className="social-media-icons">
            <a href="https://www.facebook.com/iotvts" aria-label="Facebook"><FontAwesomeIcon icon={faFacebook} size="3x" /></a>
            <a href="https://instagram.com/iotvts_tracking?igshid=ZDc4ODBmNjlmNQ==" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} size="3x" /></a>
            <a href="https://www.linkedin.com/company/iotvts/" aria-label="LinkedIn"><FontAwesomeIcon icon={faLinkedin} size="3x" /></a>
          </p>
          <p>
            <a href={`mailto:info@iotvts.com?subject=${encodeURIComponent(t('header.emailSubject'))}&body=${encodeURIComponent(t('header.emailBody'))}`}>{t('footer.contact')}</a>
          </p>
        </div>
        <div className="iot_tracking__footer-links_div">
          <h4>{t('footer.company')}</h4>
          <p><a href="https://shop.iotvts.com/legislation/termsOfService">{t('footer.termsAndConditions')}</a></p>
          <p><a href="https://shop.iotvts.com/legislation/privacyPolicy">{t('footer.privacyPolicy')}</a></p>
          <p><a href="https://shop.iotvts.com/legislation/serviceLevelAgreement">{t('footer.sla')}</a></p>
        </div>
        <div className="iot_tracking__footer-links_div">
          <h4>{t('footer.getInTouch.location')}</h4>
          <p><a href={`mailto:info@iotvts.com?subject=${encodeURIComponent(t('header.emailSubject'))}&body=${encodeURIComponent(t('header.emailBody'))}`}>{t('footer.getInTouch.email')}</a></p>
        </div>
      </div>
      <div className="responsive-container">
        <div className="iot_tracking__footer-cookie-consent">
          <CookieConsent
            location="bottom"
            buttonText={t('cookieConsent.acceptButton')}
            cookieName="cookieConsent"
            style={{ background: "#2B373B", wordWrap: "break-word" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            {t('cookieConsent.message')}
          </CookieConsent>
        </div>
      </div>
      <div className="iot_tracking__footer-copyright">
        <p>{t('footer.copyright')}</p>
      </div>

    </div>
  );
};

export default Footer;
