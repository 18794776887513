import React from 'react';
import { useTranslation } from 'react-i18next';
import { polyline, polygon, circle, route, colors } from './import';
import './geofence.css';

function Geofence() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="geofence">
      <h1 className="quick-start-title" style={{ wordWrap: 'break-word' }}>{t('geofence.title')}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t('geofence.zoneLimits')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>
                  {t('geofence.zoneDescription')}
                </p>
                <br />
                <p>
                  {t('geofence.geometryTypes')}
                </p>
                <div className="quick-start-image-container">
                  <h3 className="img-title">{t('geofence.polyline')}</h3>
                  <img src={polyline} alt="Polyline" className="img-title" title="Polyline" />
                  <h3 className="img-title">{t('geofence.polygon')}</h3>
                  <img src={polygon} alt="Polygon" className="img-title" title="Polygon" />
                  <h3 className="img-title">{t('geofence.circle')}</h3>
                  <img src={circle} alt="Circle" className="img-title" title="Circle" />
                </div>
                <br />
                <h3 className="paragraph-title">{t('geofence.permissions')}</h3>
                <p>
                  {t('geofence.deviceLink')}
                </p>
                <p>
                  {t('geofence.groupLink')}
                </p>
                <p>
                  {t('geofence.userAccess')}
                </p>
                <p>
                  {t('geofence.calendarLink')}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t('geofence.otherInfo')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>
                  {t('geofence.polylineDistance')}
                </p>
                <br />
                <p>
                  {t('geofence.color')}
                </p>
                <div className="quick-start-image-container">
                  <h3 className="img-title">{t('geofence.geofenceColor')}</h3>
                  <img src={colors} alt="colors" className="img-title" title="Geofence Color" />
                </div>
                <p>
                  {t('geofence.predefinedRoute')}
                </p>
                <br />
                <div className="quick-start-image-container">
                  <h3 className="img-title">{t('geofence.route')}</h3>
                  <img src={route} alt="route" className="img-title" title="Route" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Geofence;
