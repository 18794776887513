import React from 'react';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import PropTypes from 'prop-types';
import nbTranslation from '../../locales/nb.json';
import enTranslation from '../../locales/en.json';
import frTranslation from '../../locales/fr.json';

const LocalizationProvider = ({ children }) => (

  <I18nextProvider i18n={i18n}>
    {children}
  </I18nextProvider>
);

LocalizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocalizationProvider;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      nb: {
        translation: nbTranslation,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export { i18n };
