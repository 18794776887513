import React from 'react';
import { google, arduino, Aplicom, zigbee, dropbox, vipps, klarna } from './imports';
import './brand.css';

const Brand = () => (
  <div className="iot_tracking__brand section__padding">
    <div>
      <img src={google} alt="Google" />
    </div>
    <div>
      <img src={arduino} alt="Arduino" />
    </div>
    <div>
      <img src={Aplicom} alt="Aplicom" />
    </div>
    <div>
      <img src={dropbox} alt="Dropbox" />
    </div>
    <div>
      <img src={zigbee} alt="Zigbee" />
    </div>
    <div>
      <img src={vipps} alt="Vipps" />
    </div>
    <div>
      <img src={klarna} alt="Klarna" />
    </div>
  </div>
);

export default Brand;
