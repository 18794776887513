import React from 'react';
import { useTranslation } from 'react-i18next';
import './cta.css';

const CTA = () => {
  const { t } = useTranslation();

  return (
    <div className="iot_tracking__cta">
      <div className="iot_tracking__cta-content">
        <p>{t('footer.btn.requestQuote')}</p>
        <h3>{t('footer.heading')}</h3>
      </div>
      <div className="iot_tracking__cta-btn">
        <div className="iot_tracking__header-content__input">
          <a href="mailto:sales@iotvts.com?subject=IoT%20Tracking%20Inquiry&body=Hello,%20I%20am%20interested%20in%20using%20IoT%20Tracking.%20Please%20send%20me%20more%20information.">
            <button type="button">{t('footer.btn.requestQuote')}</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CTA;
