import React from 'react';
import { useTranslation } from 'react-i18next';
import Documentation from '../../components/documentation/Documentation';
import './documentations.css';

const Documentations = () => {
  const { t } = useTranslation();

  const featuresData = [
    {
      title: t('documentation.features.0.telegramTitle'),
      text: t('documentation.features.0.telegramText'),
      link: '#pricing',
    },
    {
      title: t('documentation.features.1.trackingTitle'),
      text: t('documentation.features.1.trackingText'),
      link: '#pricing',
    },
    {
      title: t('documentation.features.2.dedicatedTitle'),
      text: t('documentation.features.2.dedicatedText'),
      link: '#pricing',
    },
  ];

  return (
    <div className="iot_tracking__features section__padding" id="documentations">
      <div className="iot_tracking__features-heading">
        <h1 className="gradient__text">{t('documentation.title')}</h1>
        <p>{t('documentation.requestAccess')}</p>
      </div>
      <div className="iot_tracking__features-container">
        {featuresData.map((item, index) => (
          <Documentation
            title={<a href="#pricing">{item.title}</a>}
            text={item.text}
            key={item.title + index}
          />
        ))}
      </div>
    </div>
  );
};

export default Documentations;
