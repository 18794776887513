import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapPreference } from './import';
import './mapLayers.css';

function MapLayers() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="mapLayers">
      <h1 className="quick-start-title">{t('maps.title')}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t('maps.layers')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>
                  {t('maps.description1')}
                </p>
                <br />
                <p>
                  {t('maps.description2')}
                </p>
                <br />
                <div className="motionTripStops-image-container">
                  <img src={mapPreference} alt={t('maps.imageAlt')} />
                </div>
                <br />
                <p>
                  {t('maps.description3')}
                </p>
                <br />
                <h3 className="td_title">{t('maps.customMapTitle')}</h3>
                <p>
                  {t('maps.customMapDescription1')}
                </p>
                <br />
                <p>
                  {t('maps.customMapDescription2')}
                </p>
                <br />
                <p>
                  {t('maps.customMapExamples')}
                </p>
                <br />
                <ul>
                  <li className="textbox">
                    <p>Google Maps with traffic:</p>
                    <div className="text">https://mt0.google.com/vt/lyrs=m,traffic&amp;hl=en&amp;x={'{x}'}&amp;y={'{y}'}&amp;z={'{z}'}&amp;s=Ga</div>
                  </li>
                  <li className="textbox">
                    <p>ArcGis Topo:</p>
                    <div className="text">https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{'{z}'}/{'{y}'}/{'{x}'}.png</div>
                  </li>
                  <li className="textbox">
                    <p>ArcGis Imagery:</p>
                    <div className="text">https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{'{z}'}/{'{y}'}/{'{x}'}.png</div>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MapLayers;
