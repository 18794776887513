import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphs, graphs02 } from './import';
import './geofence.css';

function MotionTripStops() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="motionTripStops">
      <h1 className="quick-start-title">{t('motion.title')}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t('motion.start_stop_moving')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t('motion.standardized_motion')}</p>
                <br />
                <p>{t('motion.system_analyzes_positions')}</p>
                <br />
                <p>{t('motion.following_parameters')}</p>
                <br />
                <li>{t('motion.minimal_trip_duration')}</li>
                <li>{t('motion.minimal_parking_duration')}</li>
                <li>{t('motion.minimal_no_data_duration')}</li>
                <li>{t('motion.use_ignition')}</li>
                <li>{t('motion.minimal_trip_distance')}</li>
                <br />
                <br />
                <h3 className="paragraph-title">{t('motion.trips_and_stops')}</h3>
                <br />
                <p>{t('motion.provides_two_advanced_reports')}</p>
                <br />
                <h3 className="paragraph-title">{t('motion.examples')}</h3>
                <br />
                <p>{t('motion.first_example')}</p>
                <br />
                <div className="motionTripStops-image-container">
                  <h3 className="img-title">{t('motion.graph_report')}</h3>
                  <img src={graphs} alt="graphs" className="img-title" title={t('motion.example_reports')} />
                </div>
                <br />
                <p>{t('motion.trip_1')}</p>
                <br />
                <p>{t('motion.stop_1')}</p>
                <br />
                <p>{t('motion.trip_2')}</p>
                <br />
                <p>{t('motion.second_example')}</p>
                <br />
                <div className="motionTripStops-image-container">
                  <h3 className="img-title">{t('motion.graph_report2')}</h3>
                  <img src={graphs02} alt="graphs" className="img-title" title={t('motion.example_reports')} />
                </div>
                <br />
                <p style={{ color: 'var(--color-text)', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: '20px', lineHeight: 1.5, marginBottom: '1.2em', maxWidth: '100%', wordWrap: 'break-word' }}>{t('motion.minimal_no_data_duration_param')}</p>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MotionTripStops;
