import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { LocalizationProvider } from './components';

import App from './App';
import './index.css';

ReactDOM.render(
  <LocalizationProvider i18n={i18n}>
    <App />
  </LocalizationProvider>,
  document.getElementById('root'),
);
