import React from 'react';
import whatsapp from '../../assets/whatsapp.png';
import './whatsappButton.css';

const WhatsAppButton = () => {
  const handleClick = () => {
    const phone = 'message/GXA53TBNVCDMD1'; // replace with your phone number
    const message = '?text=How%20can%20we%20help%20you%3F'; // replace with your message
    window.open(`https://wa.me/${phone}?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <button type="button" className="whatsapp-button" onClick={handleClick}>
      <img src={whatsapp} alt="WhatsApp" />
    </button>
  );
};

export default WhatsAppButton;
