import React from 'react';
import { useTranslation } from "react-i18next";
import people from '../../assets/people.png';
import ai from '../../assets/iottracking.png';
import './header.css';

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="iot_tracking__header section__padding" id="home">
      <div className="iot_tracking__header-content">
        <h1 className="gradient__text">{t("header.title")}</h1>
        <p>{t('header.subtitle')}</p>
        <div className="iot_tracking__header-content__input">
          <input type="email" placeholder={t('header.emailPlaceholder')} />
          <a href={`mailto:info@iotvts.com?subject=${encodeURIComponent(t('header.emailSubject'))}&body=${encodeURIComponent(t('header.emailBody'))}`}>
            <button type="button">{t('header.getStartedButton')}</button>
          </a>
        </div>
        <div className="iot_tracking__header-content__people">
          <img src={people} alt={t('header.peopleAltText')} />
          <p>{t('header.peopleText')}</p>
        </div>
      </div>
      <div className="iot_tracking__header-image">
        <img src={ai} alt={t('header.aiAltText')} />
      </div>
    </div>
  );
};

export default Header;
