import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer, Pricing, Possibility, Documentations, IoTTracking } from './containers';
import { CTA, Brand, Navbar, ScrollToTopButton, WhatsAppButton } from './components';
import Header from './containers/header/Header';
import {
  Manual,
  QuickStart,
  UserManagement,
  Notifications,
  Telegram,
  IdentifyDevice,
  Geofence,
  MotionTripStops,
  Permissions,
  MapLayers,
  ComputedAttributes,
  Commands } from './documentation';

import './App.css';

const App = () => (
  <div className="App">
    <Router>
      <div className="gradient__bg">
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Header />} />
        </Routes>
      </div>
      <Brand />
      <IoTTracking />
      <Possibility />
      <CTA />
      <Pricing />
      <Documentations />
      <Manual />
      <QuickStart />
      <IdentifyDevice />
      <UserManagement />
      <Notifications />
      <Telegram />
      <Geofence />
      <MotionTripStops />
      <Permissions />
      <Commands />
      <MapLayers />
      <ComputedAttributes />
      <Footer />
      <ScrollToTopButton className="scroll-to-top-container" />
      <WhatsAppButton className="whatsapp-button" />
    </Router>
  </div>
);

export default App;
