import React from 'react';
import { useTranslation } from 'react-i18next';
import { userManagement01, userManagement02, userManagement03, userManagement04, userManagement05 } from './import';
import './userManagement.css';

function UserManagement() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="userManagement">
      <h1 className="quick-start-title">{t('userManagement.userManagementTitle')}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t('userManagement.userManagementConfig')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>
                  {t('userManagement.userManagementConfigDescription1')}
                </p>
                <p>
                  {t('userManagement.userManagementConfigDescription2')}
                </p>
                <div className="quick-start-image-container">
                  <img src={userManagement01} alt="userManagement01" />
                  <img src={userManagement02} alt="userManagement02" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t('userManagement.mainUserRolesTitle')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <br />
                <h2>{t('userManagement.adminRoleTitle')}</h2>
                <p>{t('userManagement.superUserWithUnlimitedAccess')}</p>
                <br />
                <h2>{t('userManagement.managerRoleTitle')}</h2>
                <p>{t('userManagement.managerRoleDescription')}</p>
                <br />
                <h2>{t('userManagement.userRoleTitle')}</h2>
                <p>{t('userManagement.userRoleDescription')}</p>
                <div className="quick-start-image-container">
                  <img src={userManagement01} alt="userManagement01" />
                  <img src={userManagement02} alt="userManagement02" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t('userManagement.limitedUserRoles')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <h2>{t('userManagement.readonlyUserRoleTitle')}</h2>
                <p>{t('userManagement.readonlyUserRoleDescription')}</p>
                <h2>{t('userManagement.deviceReadonlyUserRoleTitle')}</h2>
                <p>{t('userManagement.deviceReadonlyUserRoleDescription')}</p>
                <div className="quick-start-image-container">
                  <img src={userManagement03} alt="userManagement03" />
                  <img src={userManagement05} alt="userManagement05" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t("userManagement.userLimitsTitle")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <br />
                <h2>{t("userManagement.deviceLimitTitle")}</h2>
                <p>{t("userManagement.deviceLimitDescription1")}</p>
                <p>{t("userManagement.deviceLimitDescription2")}</p>
                <p>{t("userManagement.deviceLimitDescription3")}</p>
                <h2>{t("userManagement.userLimitTitle")}</h2>
                <p>{t("userManagement.userLimitDescription1")}</p>

                <p>{t("userManagement.userLimitDescription2")}</p>
                <br />
                <p>{t("userManagement.userLimitDescription3")}</p>
                <p>{t("userManagement.userLimitDescription4")}</p>
                <p>{t("userManagement.userLimitDescription5")}</p>
                <p>{t("userManagement.userLimitDescription6")}</p>
                <div className="quick-start-image-container">
                  <img src={userManagement03} />
                  <img src={userManagement04} />
                </div>
                <br />
                <h2>{t("userManagement.expirationTimeTitle")}</h2>
                <p>{t("userManagement.expirationTimeDescription")}</p>
                <br />
                <h2>{t("userManagement.disabledTitle")}</h2>
                <p>{t("userManagement.disabledDescription")}</p>
                <br />
                <p>{t("userManagement.disabledEditRestriction")}</p>
                <br />
                <p>{t("userManagement.managerEditRestriction")}</p>
                <br />
                <p>{t("userManagement.otherEditRestriction")}</p>
                <br />
                <h2>{t("userManagement.embeddedPublicTitle")}</h2>
                <p>{t("userManagement.embeddedPublicText1")}</p>
                <li>{t("userManagement.embeddedPublicListItem1")}</li>
                <li>{t("userManagement.embeddedPublicListItem2")}</li>
                <li>{t("userManagement.embeddedPublicListItem3")}</li>
                <p>{t("userManagement.embeddedPublicText2")}</p>
                <br />
                <h2>{t("userManagement.iotTrackingServiceTitle")}</h2>
                <p>{t("userManagement.iotTrackingText1")}</p>
                <br />
                <p>{t("userManagement.iotTrackingText2")}</p>
                <br />
                <p>{t("userManagement.iotTrackingText3")}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserManagement;
