import React from 'react';
import { useTranslation } from 'react-i18next';
import { imei01, imei02 } from './import';
import './identifyDevice.css';

function IdentifyDevice() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="identifyDevice">
      <h1 className="quick-start-title">{t('identifyDevice.title')}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t('identifyDevice.tableHeader')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t('identifyDevice.info')}</p>
                <ul>
                  <li>{t('identifyDevice.manufacturer')}</li>
                  <li>{t('identifyDevice.serialNumber')}</li>
                  <li>{t('identifyDevice.operatingSystem')}</li>
                </ul>
                <p>{t('identifyDevice.info2')}</p>
                <p>{t('identifyDevice.example')}</p>
                <div className="quick-start-image-container">
                  <img src={imei01} alt={t('identifyDevice.altText1')} />
                  <img src={imei02} alt={t('identifyDevice.altText2')} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default IdentifyDevice;
