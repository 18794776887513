import React from 'react';
import { useTranslation } from 'react-i18next';
import './manual.css';

function Manual() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual section__margin" id="manual">
      <div className="iottracking__manual-usermanual">
        <div className="iottracking__manual-heading">
          <h1 className="gradient__text">{t('manual.userManual')}</h1>
        </div>
        <div className="iottracking__manual-ul">
          <ul>
            <li><a href="#quickStart">{t('manual.quickStarting')}</a></li>
            <li><a href="#identifyDevice">{t('manual.identifyYourDevice')}</a></li>
            <li><a href="#userManagement">{t('manual.userManagement')}</a></li>
            <li><a href="#notifications">{t('manual.notifications')}</a></li>
            <li><a href="#telegram">{t('manual.telegramBot')}</a></li>
            <li><a href="#geofence">{t('manual.geofences')}</a></li>
            <li><a href="#motionTripStops">{t('manual.motionTripStops')}</a></li>
            <li><a href="#permissions">{t('manual.permissionsGroups')}</a></li>
            <li><a href="#commands">{t('manual.commands')}</a></li>
            <li><a href="#mapLayers">{t('manual.mapLayers')}</a></li>
            <li><a href="#computedAttributes">{t('manual.computedAttributes')}</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Manual;
