import React from 'react';
import { useTranslation } from 'react-i18next';
import Documentation from '../../components/documentation/Documentation';
import './iotTracking.css';

const IoTTracking = () => {
  const { t } = useTranslation();

  return (
    <div className="iot_tracking__whatiot_tracking section__margin" id="iotTracking">
      <div className="iot_tracking__whatiot_tracking-container">
        <Documentation
          title={t('docs.features.trackingTitle')}
          text={<p className="documentation-text">{t('docs.features.trackingText')}</p>}
        />
      </div>
      <div className="iot_tracking__whatiot_tracking-heading">
        <h1 className="gradient__text">{t('iotTracking.heading')}</h1>
        <p><a href="https://shop.iotvts.com">{t('iotTracking.explore')}</a></p>
      </div>
      <div className="iot_tracking__whatiot_tracking-container">
        <Documentation
          title={t('docs.features.telegramTitle')}
          text={t('docs.features.telegramText')}
        />
        <Documentation
          title={t('docs.features.advantageTitle')}
          text={t('docs.features.advantageText')}
        />
        <Documentation
          title={t('docs.features.completeTitle')}
          text={t('docs.features.completeText')}
        />
      </div>
    </div>
  );
};

export default IoTTracking;
