import React from 'react';
import { useTranslation } from 'react-i18next';
import { userPermissions, userManagement01, deviceConnection, groups } from './import';
import './permissions.css';

function Permissions() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="permissions">
      <h1 className="quick-start-title">{t('permissions.title')}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t('permissions.permissionsTable.title')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t('permissions.permissions-description')}</p>
                <br />
                <p>{t('permissions.control-description')}</p>
                <br />
                <div className="motionTripStops-image-container">
                  <img src={userPermissions} alt={t('permissions.user-permissions-image-alt')} />
                  <img src={userManagement01} alt={t('permissions.user-management-image-alt')} />
                </div>
                <br />
                <p>{t('permissions.shared-object-description')}</p>
                <br />
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t('permissions.devicesTable.title')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t('permissions.linked-entities-description')}</p>
                <br />
                <div className="motionTripStops-image-container">
                  <img src={deviceConnection} alt={t('permissions.device-connection-image-alt')} />
                </div>
                <br />
                <p>{t('permissions.link-from-settings-description')}</p>
                <br />
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t('permissions.groupsTable.title')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t('permissions.group-description')}</p>
                <br />
                <p>{t('permissions.geofence-events-description')}</p>
                <br />
                <p>{t('permissions.groupsTable.linkInstructions')}</p>
                <br />
                <div className="motionTripStops-image-container">
                  <img src={groups} alt={t('permissions.groupsTable.images.alt1')} />
                </div>
                <br />
                <p>{t('permissions.add-device-to-group-description')}</p>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Permissions;
