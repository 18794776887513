import React from 'react';
import { useTranslation } from 'react-i18next';
import './article.css';

const Article = ({ imgUrl, title, text }) => {
  const { t } = useTranslation();

  return (
    <div className="iot_tracking__pricing-container_article">
      <div className="iot_tracking__pricing-container_article-image">
        <img src={imgUrl} alt="pricing_image" />
      </div>
      <div className="iot_tracking__features-container__feature-title">
        <div />
        <h1>{title}</h1>
      </div>
      <div className="iot_tracking__pricing-container_article-content">
        <div />
        <div />
        <div>
          <h3 style={{ fontSize: '14px' }}>{t(text)}</h3>
        </div>
        <p><a href="https://shop.iotvts.com">{t('article.orderNow')}</a></p>
      </div>
    </div>
  );
};

export default Article;
