import React from 'react';
import { useTranslation } from 'react-i18next';
import { command01, command02, command03 } from './import';
import './commands.css';

function Commands() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="commands">
      <h1 className="quick-start-title">{t('commands.title')}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t('commands.remote_control_commands')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t('commands.support_commands')}</p>
                <br />
                <p>{t('commands.custom_command_hex_text')}</p>
                <br />
                <p>{t('commands.sms_telegram')}</p>
                <br />
                <p>{t('commands.command_results')}</p>
                <br />
                <p>{t('commands.command_queue')}</p>
                <br />
                <p>{t('commands.byDefaultAllRegularUsers')}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th className="th-title">{t('commands.saved_commands')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t('commands.saved_commands_description')}</p>
                <br />
                <br />
                <p>{t('commands.create_saved_command')}</p>
                <div className="motionTripStops-image-container">
                  <img src={command01} alt="Create a saved command" />
                </div>
                <br />
                <p>{t('commands.link_saved_command')}</p>
                <br />
                <div className="motionTripStops-image-container">
                  <img src={command02} alt="Link a saved command" />
                </div>
                <br />
                <br />
                <p>{t('commands.send_saved_command')}</p>
                <div className="motionTripStops-image-container">
                  <img src={command03} alt="Send a saved command" />
                </div>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Commands;
