import React from 'react';
import { useTranslation } from 'react-i18next';
import { computedAttrib, graphsChart } from './import';
import './computedAttributes.css';

function ComputedAttributes() {
  const { t } = useTranslation();

  return (
    <div className="iottracking__manual .quick-start-container" id="computedAttributes">
      <h1 className="quick-start-title">{t('computedAttributes.title')}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t('computedAttributes.tableHeader')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body iottracking-container__feature-text">
                <p>
                  {t('computedAttributes.description')}
                </p>
                <h2>{t('computedAttributes.useCasesTitle')}</h2>
                <br />
                <ul className="iottracking-container__feature-text">
                  <li>{t('computedAttributes.newUser')}</li>
                  <li>{t('computedAttributes.readOnlyUser')}</li>
                </ul>
                <br />
                <p>
                  {t('computedAttributes.positionApplied')}
                </p>
                <br />
                <h2>{t('computedAttributes.fieldsTitle')}</h2>
                <br />
                <ul className="iottracking-container__feature-text">
                  <li>{t('computedAttributes.fieldsDescription1')}</li>
                  <li>{t('computedAttributes.fieldsDescription2')}</li>
                  <li>{t('computedAttributes.fieldsDescription3')}</li>
                  <li>{t('computedAttributes.fieldsDescription4')}</li>
                </ul>
                <br />
                <p>
                  {t('computedAttributes.customAttribute')}
                </p>
                <br />
                <br />
                <h2 className="td_title">{t('computedAttributes.expressionTitle')}</h2>
                <p>
                  {t('computedAttributes.expressionDescription1')}
                </p>
                <br />
                <p>
                  {t('computedAttributes.expressionDescription2')}
                </p>
                <br />
                <p>
                  {t('computedAttributes.expressionDescription3')}
                </p>
                <p>
                  {t('computedAttributes.expressionTesting')}
                </p>
                <div className="motionTripStops-image-container">
                  <img src={computedAttrib} />
                </div>
                <br />
                <br />
                <h3 className="td_title">{t('computedAttributes.examplesTitle')}</h3>
                <br />
                <ul>
                  <li>
                    <p>{t('computedAttributes.example1')}</p>
                    <div className="textbox">
                      <p>Ignition</p>
                      <p>power ? power &gt; 13.2 : null</p>
                      <p>Boolean</p>
                    </div>
                  </li>
                  <br />
                  <p>
                    {t('computedAttributes.example2')}
                  </p>
                  <li>
                    <p>{t('computedAttributes.example3')}</p>
                    <div className="textbox">
                      <p>Ignition</p>
                      <p>in2 ? true : false</p>
                      <p>Boolean</p>
                    </div>
                  </li>
                  <li>
                    <p>{t('computedAttributes.example4')}</p>
                    <div className="textbox">
                      <p>Ignition</p>
                      <p>in2 ? in2 == 1 :false</p>
                      <p>Boolean</p>
                    </div>
                  </li>
                  <br />
                  <br />
                  <li>
                    <p>{t('computedAttributes.example5')}</p>
                    <div className="textbox">
                      <p>Ignition</p>
                      <p>flags ? (flags & 2) ! = 0 :false</p>
                      <p>Boolean</p>
                    </div>
                  </li>
                  <br />
                  <br />
                  <li>
                    <p>{t('computedAttributes.example6')}</p>
                    <div className="textbox">
                      <p>Ignition</p>
                      <p>adc1 ? adc1 * 0.065 :null</p>
                      <p>Number</p>
                    </div>
                  </li>
                  <br />
                  <br />
                  <li>
                    <p>{t('computedAttributes.example7')}</p>
                    <div className="motionTripStops-image-container">
                      <img src={graphsChart} />
                    </div>
                    <br />
                    <div className="textbox">
                      <p>Ignition</p>
                      <p>battery {'<'} 4.18 && speed {'<'} 1 ? false : true</p>
                      <p>Boolean</p>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ComputedAttributes;
