import React from "react";
import { useTranslation } from "react-i18next";
import { notification02, notifications } from "./import";
import "./notifications.css";

function Notifications() {
  const { t } = useTranslation();

  return (
    <div
      className="iottracking__manual .quick-start-container"
      id="notifications"
    >
      <h1 className="quick-start-title">{t("notifications.title")}</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th className="th-title">
                {t("notifications.events_and_notifications")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <h2>{t("notifications.events_and_notifications")}</h2>
                <p>{t("notifications.supported_event_types")}</p>
                <br />
                <p>{t("notifications.notification_configuration")}</p>
                <div className="quick-start-image-container">
                  <img src={notification02} />
                  <img src={notifications} />
                </div>
                <br />
                <p>{t("notifications.default_notification_channels")}</p>
                <p>{t("notifications.add_remove_channels")}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="td">{t("notifications.alarm_types")}</h3>
        <h4 className="td">{t("notifications.most_commonly_used_alarms")}</h4>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t("notifications.type")}</th>
              <th className="th-title">{t("notifications.description")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t("notifications.general")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.generic_case")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.sos")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.panic_alarm")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.vibration")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.stationary_device_moving")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.overspeed")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.speeding_alarm")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.low_power")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.external_power_supply")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.low_battery")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.internal_power_supply")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.geofence_enter")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.geofence_enter_description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.geofence_exit")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.geofence_exit_description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.tampering")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.tampering_description")}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3 className="td">{t("notifications.event_types")}</h3>
        <table>
          <thead>
            <tr>
              <th className="th-title">{t("notifications.event_type")}</th>
              <th className="th-title">{t("notifications.event_description")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-body">
                <p>{t("notifications.command_result")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.command_result_description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_online")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.status_online_description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_unknown")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.status_unknown_description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_offline")}</p>
              </td>
              <td className="td_body">
                <p>{t("notifications.status_offline_description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_inactive")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_inactive-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_moving")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_moving-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_stopped")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_stopped-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_spedd_limit")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_spedd_limit-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_fuel_drop")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_fuel_drop-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_fuel_increase")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_fuel_increase-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_geofence_enter")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_geofence_enter-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_geofence_exit")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_geofence_exit-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_alarm")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_alarm-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_ignitionOn")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_ignitionOn-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_ignitionOff")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_ignitionOff-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_maintenanceRequired")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_maintenanceRequired-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_driver_changed")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_driver_changed-description")}</p>
              </td>
            </tr>
            <tr>
              <td className="td-body">
                <p>{t("notifications.status_media")}</p>
              </td>
              <td className="td-body">
                <p>{t("notifications.status_media-description")}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default Notifications;
